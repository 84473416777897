::Object.require 'corelib/runtime'
::Object.require 'corelib/helpers'
::Object.require 'corelib/module'
::Object.require 'corelib/class'
::Object.require 'corelib/basic_object'
::Object.require 'corelib/kernel'
::Object.require 'corelib/main'
::Object.require 'corelib/error'

::Object.require 'corelib/constants'
